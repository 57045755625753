//// LOCAL ////

// const website_URL = "http://localhost:4502"

const changePassword_URL = "https://msovcode.b2clogin.com/msovcode.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=54d92333-55f6-45a8-b93a-3e1c0f8d4d34&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3001&scope=openid&response_type=id_token&prompt=login"

//// DEVELOPMENT INSTANCE ////

// const website_URL = "https://thankful-mud-0b8027d1e.2.azurestaticapps.net"
const website_URL = "https://dev-management.ovcode.com"

// const changePassword_URL = "https://msovcode.b2clogin.com/msovcode.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=54d92333-55f6-45a8-b93a-3e1c0f8d4d34&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fthankful-mud-0b8027d1e.2.azurestaticapps.net&scope=openid&response_type=id_token&prompt=login"
 

// const baseURL = "https://cybergem.azure-api.net"
const baseURL = "https://ovcodedevapim.ovcode.com"
const subscriptionKey = "f3ee95c3f78e487abec52c745f991a48"

const apiDocumentationURL = "https://dev-docs.ovcode.com"


 

export default {

 

    business_account_service : {

        url: baseURL ,

        subscriptionKey : subscriptionKey,

    },

 

    msal_url : {

        url: website_URL,

    },

 

    msal_change_password_url : {

        url: changePassword_URL,

    },

 

    msal_authConfig:{

        clientId:'54d92333-55f6-45a8-b93a-3e1c0f8d4d34',

        knownAuthorities: ["msovcode.b2clogin.com"],

        authority:'https://msovcode.b2clogin.com/tfp/msovcode.onmicrosoft.com/B2C_1_ovcode_admin',

        postLogoutRedirectUri: website_URL,

    },

    b2c_scope:{

        scopes:[

            "https://msovcode.onmicrosoft.com/54d92333-55f6-45a8-b93a-3e1c0f8d4d34/Admin"

        ]

    },

    apiDocumentationURL: apiDocumentationURL,

};