import AccountManagementCreateScreen from "../screens/AccountManagementCreateScreen.jsx";
import AccountManagementListScreen from "../screens/AccountManagementListScreen.jsx";
import AccountManagementViewScreen from "../screens/AccountManagementViewScreen.jsx";
import AnalyticsScreen from "../screens/AnalyticsScreen.jsx";
import DashboardScreen from "../screens/dashboard.screen.jsx";
import LandingScreen from "../screens/Landing.screen.jsx";
import ServiceMonitoringScreen from "../screens/ServiceMonitoringScreen.jsx";
import ViewProfileScreen from "../screens/ViewProfileScreen.jsx";
import UpdateProfileScreen from "../screens/UpdateProfileScreen";
import UpdateBusinessAccountScreen from "../screens/UpdateBusinessAccountScreen";
import UserListScreen from "../screens/UserListScreen.jsx";
import EmailAuthenticationScreen from "../screens/EmailAuthenticationScreen.jsx";
import EmailAuthenticationDetailsComponent from "../components/email-authentication/email-authentication-details.component.jsx";
import EmailAuthenticationProjectDetails from "../components/email-authentication/email-authentication-project-details.component.jsx";
import AppConfigurationScreen from "../screens/AppConfigurationScreen.jsx";
import ViewAppConfigurationScreen from "../screens/ViewAppConfigurationScreen.jsx";
import SignInLogsScreen from "../screens/SignInLogsScreen.jsx";
import ReportsScreen from "../screens/ResportsScreen.jsx";
import ReportsTwoComponent from "../components/reports/report-two.component.jsx";
import ReportsThreeComponent from "../components/reports/report-three.component.jsx";
import ViewPermissionScreen from "../screens/ViewPermissionScreen.jsx";
import AllReportsComponent from "../components/reports/all-reports.component.jsx";
import SelectPlanAndAddCreditsScreen from "../screens/SelectPlansAndAddCreditsScreen.jsx";
import AddEnterpriseUserScreen from "../screens/AddEnterpriseUserScreen.jsx";
import ViewBusinessDetailsScreen from "../screens/ViewBusinessDetailsScreen.jsx";
import AdminSettingsScreen from "../screens/AdminSettingsScreen.jsx";
import AddTemplateScreen from "../screens/AddTemplateScreen.jsx";
import PDFTemplateScreen from "../screens/PDFTemplateScreen.jsx";
import TestScreen from "../screens/TestScreen.jsx";
import ForbiddenScreen from "../screens/ForbiddenScreen.jsx";
import FilePreviewComponent from "../components/enterprise/vault/file-preview.component.jsx";
import UserSignInLogsScreen from "../screens/UserSigninLogsScreen.jsx";

export const routes = [
    
    {
        path: '/',
        name: 'LandingScreen',
        element: <LandingScreen />,
        isPrivate: false
    },
    {
        path: '/forbidden',
        name: 'ForbiddenScreen',
        element: <ForbiddenScreen />,
        isPrivate: false
    },
    {
        path: '/dashboard',
        name: 'DashboardScreen',
        element: <DashboardScreen />,
        isPrivate: true
    },
    {
        path: '/reports',
        name: 'ReportsScreen',
        element: <ReportsScreen />,
        isPrivate: true
    },
    {
        path: '/reports-two',
        name: 'ReportsTwoComponent',
        element: <ReportsTwoComponent />,
        isPrivate: true
    },
    {
        path: '/all-reports-two',
        name: 'ReportsTwoComponent',
        element: <AllReportsComponent />,
        isPrivate: true
    },
    {
        path: '/reports-three',
        name: 'ReportsThreeComponent',
        element: <ReportsThreeComponent/>,
        isPrivate: true
    },
    {
        path: '/sign-in-logs',
        name: 'SignInLogsScreen',
        element: <SignInLogsScreen />,
        isPrivate: true
    },

    {
        path: '/account-management',
        name: 'AccountManagementListScreen',
        element: <AccountManagementListScreen />,
        isPrivate: true
    },

    {
        path: '/view',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/dashboard',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/details',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/projects',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/vault',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/authentication',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/templates',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/analytics',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/users',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/reports',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/plan',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view/activity-logs',
        name: 'AccountManagementViewScreen',
        element: <AccountManagementViewScreen />,
        isPrivate: true
    },

    {
        path: '/view-enterprise-detials',
        name: 'ViewBusinessDetailsScreen',
        element: <ViewBusinessDetailsScreen />,
        isPrivate: true
    },

    {
        path: '/create',
        name: 'AccountManagementCreateScreen',
        element: <AccountManagementCreateScreen />,
        isPrivate: true
    },

    {
        path: '/add-enterprise-plan-and-credits',
        name: 'SelectPlanAndAddCreditsScreen',
        element: <SelectPlanAndAddCreditsScreen />,
        isPrivate: true
    },

    {
        path: '/add-enterprise-user',
        name: 'AddEnterpriseUserScreen',
        element: <AddEnterpriseUserScreen />,
        isPrivate: true
    },

    {
        path: '/analytics',
        name: 'AnalyticsScreen',
        element: <AnalyticsScreen />,
        isPrivate: true
    },

    {
        path: '/service-monitoring',
        name: 'ServiceMonitoringScreen',
        element: <ServiceMonitoringScreen />,
        isPrivate: true
    },

    {
        path: '/view-profile',
        name: 'ViewProfileScreen',
        element: <ViewProfileScreen />,
        isPrivate: true
    },

    {
        path: '/update-profile',
        name: 'UpdateProfileScreen',
        element: <UpdateProfileScreen />,
        isPrivate: true
    },

    {
        path: '/edit',
        name: 'UpdateBusinessAccountScreen',
        element: <UpdateBusinessAccountScreen />,
        isPrivate: true
    },

    {
        path: '/user-list',
        name: 'UserListScreen',
        element: <UserListScreen />,
        isPrivate: true
    },

    {
        path: '/email-authentication',
        name: 'EmailAuthenticationScreen',
        element: <EmailAuthenticationScreen />,
        isPrivate: true
    },

    {
        path: '/authentication-details',
        name: 'EmailAuthenticationDetailsComponent',
        element: <EmailAuthenticationDetailsComponent />,
        isPrivate: true
    },

    {
        path: '/project-details',
        name: 'EmailAuthenticationProjectDetails',
        element: <EmailAuthenticationProjectDetails />,
        isPrivate: true
    },

    {
        path: '/app-configuration',
        name: 'AppConfigurationScreen',
        element: <AppConfigurationScreen />,
        isPrivate: true
    },

    {
        path: '/app-configuration-details',
        name: 'ViewAppConfigurationScreen',
        element: <ViewAppConfigurationScreen/>,
        isPrivate: true
    },

    {
        path: '/view-permission',
        name: 'ViewPermissionScreen',
        element: <ViewPermissionScreen/>,
        isPrivate: true
    },
    
    {
        path: '/admin-settings',
        name: 'AdminSettingsScreen',
        element: <AdminSettingsScreen/>,
        isPrivate: true
    },

    {
        path: '/add-template',
        name: 'AddTemplateScreen',
        element: <AddTemplateScreen/>,
        isPrivate: true
    },

    {
        path: '/view-template',
        name: 'PDFTemplateScreen',
        element: <PDFTemplateScreen />,
    },

    {
        path: '/update-template',
        name: 'UpdateTemplateScreen',
        element: <PDFTemplateScreen />,
    },

    {
        path: '/test-screen',
        name: 'TestScreen',
        element: <TestScreen />,
    },

    {
        path: '/file-preview',
        name: 'FilePreviewComponent',
        element: <FilePreviewComponent/>,
    },

    {
        path: '/user-signin-logs',
        name: 'UserSignInLogsScreen',
        element: <UserSignInLogsScreen/>,
    },


];