import { Avatar, Box, Button, ButtonGroup, Grid, IconButton, TextField, Skeleton, Typography, InputAdornment, Link, CardContent, CardHeader, CardActions, Card } from "@mui/material";
import environment from "../../environment/environment";

function ViewProfileComponent(props) {

    const { breakpoints, admin, loading, role } = props;


    // MSAL CHANGE PASSWORD
    const updatePassword = environment.msal_change_password_url.url;
    /* end */

    function stringAvatar(name) {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        return {
            children: `${((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()}`,
        };
    }

    console.log("adminxx", admin)

    // Check if admin object and identities array exist
    const email = admin && admin?.identities[0]?.issuerAssignedId || '';

    // Extract the part before '@' symbol
    const displayName = email?.split('@')[0];


    return (
        <Box sx={{ width: breakpoints == 'xs' ? "100%" : '80%', margin: 'auto' }}>
            <Card sx={{ padding: breakpoints == 'xs' ? 1 : 6, pl: 0, pr: 0, borderRadius: 2, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: breakpoints == 'xs' ? "100%" : "80%", margin: 'auto' }}>
                <CardContent sx={{textAlign: "center"}}>
                    {loading ? (
                        <>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} sm={12}>
                                    <Skeleton variant="circular" width={100} height={100} sx={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{mb: 2, textAlign: "center"}}>
                                <Grid item xs={12} sm={12} sx={{alignSelf: "center"}}>
                                    <Skeleton variant="text" width={"25%"} height={30} sx={{mt: "12px", margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                </Grid>

                                <Grid item xs={12} sm={12} sx={{alignSelf: "center", display: 'flex', justifyContent: "center", textAlign: 'center', margin: 'auto' }}>
                                        <Skeleton variant="text" width={"50%"} height={30} sx={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                </Grid>

                                <Grid item xs={12} sm={12} sx={{alignSelf: "center", display: 'flex', justifyContent: "center", textAlign: 'center', margin: 'auto' }}>
                                    <Skeleton variant="text" width={"50%"} height={30} sx={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                </Grid>
                            </Grid>
                        </>

                    ) : (
                        <>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} sm={12}>
                                    <Avatar
                                        {...stringAvatar(admin && displayName)}
                                        sx={{ width: 100, height: 100, fontSize: 36, backgroundColor: '#007fff', backgroundSize: 'cover', '&.MuiAvatar-img': { borderRadius: '50% !important' }, margin: 'auto' }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mb: 2, textAlign: 'center' }}>
                                <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                    <Typography className="card-account-email" sx={{ fontSize: 16, mt: "10px", fontWeight: 'bold', mb: 2}}>
                                        {admin && displayName}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} sx={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center', margin: 'auto' }}>
                                    <Typography sx={{ fontWeight: 700, fontSize: 16 }}>Email Address:</Typography>
                                        &nbsp;
                                    <Typography className="card-account-email" sx={{ fontSize: 16 }}>
                                        {admin && admin?.identities[0].issuerAssignedId}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} sx={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center', margin: 'auto' }}>
                                    <Typography sx={{ fontWeight: 700, fontSize: 16 }}>Role:</Typography>
                                        &nbsp;
                                    <Typography className="card-account-email" sx={{fontSize: 16}}>
                                        {role}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    )

}

export default ViewProfileComponent