import React, {useState} from "react";
import {Button, ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {ThreeDots} from 'react-loader-spinner';
import useUsers from "../../hooks/useUsers";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import UpdateUserRoleDialog from "./dialogs/update-user-role.dialog";
import UpdateAdminUserRoleDialog from "./dialogs/update-admin-user-role.dialog";


const ActionsPopperComponent = (props) => {

    const {breakpoints, userId, status, setOpenEditEmail, userEmail, userRole, projectId, getUsersByBusinessAccountID, businessId} = props;

    const url = window.location.pathname;

    const {isUpdatingUserStatus, updateUserStatus , deleteUser, deletingUser, updateAdminUserStatus, deleteAdminUserStatus} = useUsers();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdateRole, setOpenUpdateRole] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const handleCloseUpdateRole = () => {
        setOpenUpdateRole(false);
    }

    const handleDeactivateStatus = () => {
        let payload = {
            data: {
                accountEnabled: false
            }
        }

        if(url === "/admin-settings"){
            updateAdminUserStatus(userId, payload);
        }else{
            updateUserStatus(userId, payload);

        }
 
    }

    const handleActivateStatus = () => {
        let payload = {
            data: {
                accountEnabled: true
            }
        }
 
        if(url === "/admin-settings"){
            updateAdminUserStatus(userId, payload);
        }else{
            updateUserStatus(userId, payload);

        }
    }


    const handleDeleteUser = () => {
        let payload = {
            data: {
                accountEnabled: false
            }
        }

        if(url === "/admin-settings"){
            deleteAdminUserStatus(userId, payload);
        }else{
            deleteUser(userId, payload);
        }
 
        
    }



    return (
        <div>
            <IconButton sx={{color: "#000"}} className="card-manage">
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={(e) => handleClick(e)} />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem style={{paddingLeft: "8px !important", color: '#0178f2', fontSize: 13, fontWeight: 500}}
                                onClick={(e) => setOpenEditEmail(true)}
                            >
                                <ListItemIcon style={{padding: 0, minWidth: 30, marginLeft: "-5px"}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: "#0178f2"}}>
                                        <EditIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">
                                    Edit email
                                </Typography>
                            </MenuItem>

                            <MenuItem style={{paddingLeft: "8px !important", color: '#0178f2', fontSize: 13, fontWeight: 500}}
                                onClick={(e) => setOpenUpdateRole(true)}
                            >
                                <ListItemIcon style={{padding: 0, minWidth: 30, marginLeft: "-5px"}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: "#0178f2"}}>
                                        <ManageAccountsIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">
                                    Edit Role
                                </Typography>
                            </MenuItem>

                            {((status == 1) ?
                                <MenuItem sx={{fontSize: 130, fontWeight: 500, paddingLeft: (isUpdatingUserStatus) ? {} : "10px !important"}}>
                                    <Button variant="text" sx={{textTransform: 'none', padding: '0px !important'}}
                                        onClick={(e) => handleDeactivateStatus()}
                                        startIcon={isUpdatingUserStatus && <ThreeDots height="20" width="20" radius="9" color="red" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {(!isUpdatingUserStatus) &&
                                            <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                                <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                                    <BlockOutlinedIcon style={{width: 16, height: 16, color: 'red'}} />
                                                </IconButton>
                                            </ListItemIcon>
                                        }
                                        
                                        <Typography variant="inherit" sx={{color: 'red'}}>
                                            {isUpdatingUserStatus ? "Deactivating" : "Deactivate"}
                                        </Typography>
                                    </Button>
                                </MenuItem>

                            :
                                <MenuItem sx={{fontSize: 13, fontWeight: 500, paddingLeft: (isUpdatingUserStatus) ? {} : "10px !important"}}>
                                    <Button variant="text" sx={{textTransform: 'none', padding: '0px !important'}}
                                        onClick={(e) => handleActivateStatus(e)}
                                        startIcon={isUpdatingUserStatus && <ThreeDots height="20" width="20" radius="9" color="green" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {!isUpdatingUserStatus &&
                                            <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                                <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                                    <CheckCircleOutlineOutlinedIcon style={{width: 16, height: 16, color: 'green'}} /> 
                                                </IconButton>
                                            </ListItemIcon>
                                        }
                                        
                                        <Typography variant="inherit" sx={{color: 'green'}}>
                                            {isUpdatingUserStatus ? "Activating" : "Activate"}
                                        </Typography>
                                    </Button>
                                </MenuItem>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            
            {openUpdateRole &&
                url === "/admin-settings" ?
                    <UpdateAdminUserRoleDialog 
                        openUpdateRole={openUpdateRole}
                        handleCloseUpdateRole={handleCloseUpdateRole}
                        userEmail={userEmail}
                        userId={userId}
                        userRole={userRole}
                    />
                :

                <UpdateUserRoleDialog 
                    openUpdateRole={openUpdateRole}
                    handleCloseUpdateRole={handleCloseUpdateRole}
                    userEmail={userEmail}
                    userId={userId}
                    userRole={userRole}
                    projectId={projectId}
                    getUsersByBusinessAccountID={getUsersByBusinessAccountID}
                    businessId={businessId}
                />
            }


        </div>
    )
}

export default ActionsPopperComponent
