import { pdfPageSizes } from "../common/staticVariables";

const getProportionalCoords = (rotation, currentWidth, currentHeight, origWidth, origHeight, x, y) => {
    let isLandscape = rotation == 90 || rotation == 270 ? true : false
    console.log("rotation 11", rotation);
    console.log("isLandscape 11", isLandscape);
    console.log("origWidth 11", origWidth);
    console.log("origHeight 11", origHeight);
    console.log("currentWidth 11", currentWidth);
    console.log("currentHeight 11", currentHeight);

    let pageWidth = isLandscape ? origHeight : origWidth
    let pageHeight = isLandscape ? origWidth : origHeight

    console.log("pageWidth 11", pageWidth);
    console.log("pageHeight 11", pageHeight);

    let propX = (x * pageWidth) / currentWidth
    let propY = (y * pageHeight) / currentHeight

    return {x: propX, y: propY}
}

const getReverseProportionCoords = (rotation, currentWidth, currentHeight, origWidth, origHeight, x, y, isOriginalPageDimensRotated = true) => {
    let isLandscape = rotation == 90 || rotation == 270 ? true : false
    console.log("rotation 11", rotation);
    console.log("isLandscape 11", isLandscape);

    let pageWidth = isOriginalPageDimensRotated && isLandscape ? origHeight : origWidth
    let pageHeight = isOriginalPageDimensRotated && isLandscape ? origWidth : origHeight

    console.log("x 11", x);
    console.log("y 11", y);
    console.log("pageWidth 11", pageWidth);
    console.log("pageHeight 11", pageHeight);

    let propX = (x * currentWidth) / pageWidth
    let propY = (y * currentHeight) / pageHeight

    console.log("propX aa11", propX);
    console.log("propY aa11", propY);

    return {x: propX, y: propY}
}

const getProportionalDimens = (rotation, currentWidth, currentHeight, origWidth, origHeight, w, h) => {
    let isLandscape = rotation == 90 || rotation == 270 ? true : false

    let pageWidth = isLandscape ? origHeight : origWidth
    let pageHeight = isLandscape ? origWidth : origHeight

    let propElWidth = (w * pageWidth) / currentWidth
    let propElHeight = (h * pageHeight) / currentHeight

    return {w: propElWidth, h: propElHeight}
}

const getReverseProportionDimens = (rotation, currentWidth, currentHeight, origWidth, origHeight, w, h, isOriginalPageDimensRotated = true) => {
    let isLandscape = rotation == 90 || rotation == 270 ? true : false

    let pageWidth = isOriginalPageDimensRotated && isLandscape ? origHeight : origWidth
    let pageHeight = isOriginalPageDimensRotated && isLandscape ? origWidth : origHeight

    let propElWidth = (w * currentWidth) / pageWidth
    let propElHeight = (h * currentHeight) / pageHeight

    return {w: propElWidth, h: propElHeight}
}

const getWidthByPercentage = (percentage, parentWidth) => {
    let adjustedWidth = (percentage / 100) * parentWidth;
    return adjustedWidth
}

const getProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size) => {
    let isLandscape = rotation == 90 || rotation == 270 ? true : false
    let pageWidth = isLandscape ? pageOrigHeight : pageOrigWidth
    console.log("rotation from util 11", rotation);
    console.log("pageOrigWidth from util 11", pageOrigWidth);
    console.log("pageWidth from util 11", pageWidth);

    let propFontSize = (font_size * pageWidth) / dropRefCurrentWidth
    console.log("propFontSize from util 11", propFontSize);

    return {font_size: propFontSize}
}

const getReverseProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size, isOriginalPageDimensRotated = true) => {
    let isLandscape = rotation == 90 || rotation == 270 ? true : false
    let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOrigHeight : pageOrigWidth
    console.log("rotation from util 11", rotation);
    console.log("pageOrigWidth from util 11", pageOrigWidth);
    console.log("pageWidth from util 11", pageWidth);

    let propFontSize = (font_size * dropRefCurrentWidth) / pageWidth
    console.log("propFontSize from util 11", propFontSize);

    return {font_size: propFontSize}
}

export {getProportionalCoords, getReverseProportionCoords, getProportionalDimens, getReverseProportionDimens, getWidthByPercentage, getProportionalElementFontSize, getReverseProportionalElementFontSize}