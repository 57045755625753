import React, { useEffect, useState } from "react";
import {Select, FormControl, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, easing } from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";
import UserService from "../../../services/api/users";
import useUsers from "../../../hooks/useUsers";

const UpdateAdminUserRoleDialog = (props) => {

    const { breakpoints, openUpdateRole, handleCloseUpdateRole, userEmail, userId, userRole } = props;

    const { enqueueSnackbar } = useSnackbar();

    const { getAdminRoles, adminRoles, retrieved, getAllAdminRoles } = useUsers();

    const [loading, setLoading] = useState(false);
    const [userRoleUpdated, setUserRoleUpdated] = useState(false);
    const [role, setRole] = useState(userRole);
    const [page, setPage] = useState(1);

    const url = window.location.pathname;


    // useEffect(() => {
    //     getAdminRoles();
    // }, [])

    useEffect(() => {
        getAllAdminRoles();
    },[])

    useEffect(() => {
        if(retrieved){
            getAdminRoles(page);
        }
    },[retrieved, page])

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    }

    const handleCloseDialog = (e) => {
        handleCloseUpdateRole();
    }

    const handleSubmit = async () => {

        try {
            setLoading(true);
            const res = await UserService.updateAdminUserRole(userId, role);
            console.log('update admin user role res', res);
            setLoading(false);
            setUserRoleUpdated(true);
            enqueueSnackbar("User role updated successfully.", {
                variant: "success",
                autoHideDuration: 3000,
            });
        } catch (err) {
            console.log("error bbbb", err)
            setLoading(false);
            enqueueSnackbar(err.response.data.error, {
                variant: "error",
                autoHideDuration: 3000,
            });
            handleCloseDialog();
            console.log('error', err);
        }
       
    };


    useEffect(() => {
        if (userRoleUpdated) {
            handleCloseDialog();

            if (url === "/admin-settings") {
                window.location.reload();
            }
        }

    }, [userRoleUpdated])

    return (
        <Dialog open={openUpdateRole} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{ style: { boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto" } }}>
            <DialogTitle>
                Edit User Role
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? 3 : "24px 48px 28px 48px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ alignSelf: "center", fontWeight: 550 }}>
                        Email
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <TextField
                            fullWidth
                            value={userEmail}
                            disabled
                            sx={{ "& .MuiInputBase-root": { height: 48, backgroundColor: 'white', width: '100%' } }}
                            inputProps={{
                                style: { padding: "10px", fontSize: 14, fontStyle: 'italic' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ alignSelf: "center", fontWeight: 550 }}>
                        Role
                    </Grid>
                    <Grid item xs={12} sx={{ pt: "4px !important" }}>

                        <FormControl sx={{width: "100%"}}>
                            <Select
                                labelId="demo-simple-select-label"
                                value={role}
                                sx={{
                                    minWidth: '100%', width: '100%', height: 48, backgroundColor: 'white',
                                    '&.Mui-focused': {
                                        border: 'none',
                                    },
                                }}
                                onChange={handleChangeRole}
                                inputProps={{sx: {mr: "0px", fontSize: 14, textAlign: "left"}}}
                                MenuProps={{PaperProps: {style: {maxHeight: 48 * 4.5 + 8, width: 300}}}}
                            >
                                    {adminRoles?.map((data, index) => (
                                        <MenuItem key={data?.id} value={data?.role}>
                                            {data?.role}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
                    
            </DialogContent>

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button sx={{ color: "black", textTransform: "none" }} onClick={(e) => handleCloseDialog(e)}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                    onClick={(e) => handleSubmit(e)}
                    startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {loading ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateAdminUserRoleDialog