import React, {useState, useRef} from "react";
import {Box, Grid, IconButton, Tooltip, Typography, Stack} from "@mui/material";
// import {Document, pdfjs} from 'react-pdf';
import { Document, Page, pdfjs  } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard"; 
import {useSnackbar} from 'notistack';
import PDFPageComponent from "./pdf-page.component";
import { getWidthByPercentage } from "../../utils/ProportionDimenUtil";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFBoxComponent = ({file_url, template_code_queryParam, adjustedPDFWidth, templateProperties, elements, setElements, setTemplatePropertiesPayload, 
    setDeletedPropertyIdsPayload, isViewing, templatePropertiesPayload, template, isLoading, canConfigTemplate, setHasSeal,
    setUserSignatureUpdated, userSignatureUpdated, deletedElements, setDeletedElements, signatureUpdated, setSignatureUpdated, signatureDeleted, setSignatureDeleted,
    getB2CProfile, saveTemplateElementToCache, currentSelectedUser, setCurrentSelectedUser}) => {

    const [pdfPages, setPdfPages] = useState([])
    const [numPages, setNumPages] = useState(null);

    const pdfEditorRef = useRef()

    const {enqueueSnackbar} = useSnackbar();
    // const [numPages, setNumPages] = useState(null);
  
    // function onDocumentLoadSuccess(pdf) {
    //     setNumPages(pdf.numPages);
    // }

    const copyToClipboard = (template) => {
        copy(template.template_code);

        enqueueSnackbar("Successfully copied template code to clipboard", { 
            variant: "success",
            autoHideDuration: 2000
        });
    }

    async function handleOnDocumentLoadSuccess(pdf){
        console.log("pdfpdfpdfpdfpdfpdf: ", pdf)

        handleSetPDFPages(pdf)
        setNumPages(pdf.numPages);
    }

    const handleSetPDFPages = (pdf) => {
        //TODO make comments

        [...Array(pdf.numPages)].map((item, i) => {
            let pageNum = i + 1
            pdf.getPage(pageNum).then((page) => {
                let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth

                let percentage = 60
                let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)

                let pdfPageOrigWidth = page._pageInfo.view[2]
                let pdfPageOrigHeight = page._pageInfo.view[3]

                let rotate = page.rotate
                let isLandscape = rotate == 90 || rotate == 270 ? true : false
                let widthDiff = isLandscape ? pdfPageOrigHeight - percentageWidth : 0
                let isNegative = widthDiff < 0 ? true : false
                let newNum = isLandscape ? pdfPageOrigHeight + widthDiff : 0
                let origNum = isLandscape ? pdfPageOrigHeight : pdfPageOrigWidth
                let percentageDiff = Math.abs(getPercentageIncreaseDecrease(newNum, origNum))
                percentageDiff = isNegative ? Math.abs(percentageDiff) : -Math.abs(percentageDiff)

                // let sortedData = page.sort((a, b) => a.page_number - b.page_number);

                console.log('get pdf page on editor', page)

                let obj = {
                    numPages: pdf.numPages,
                    page_number: page.pageNumber,
                    isLandscape,
                    page,
                    ...(isLandscape && {adjustedWidth: percentageWidth, percentageDiff, widthDiff})
                }

                setPdfPages((prev) => {
                    let _page = prev.find((item) => item.isLandscape)

                    // For portrait
                    if (_page && !isLandscape) {
                        let portrait_percentage_width = getWidthByPercentage(_page.percentageDiff, pdfPageOrigWidth)
                        obj.adjustedWidth = portrait_percentage_width + pdfPageOrigWidth
                    }
                    return [...prev, obj]
                })
            })
        })

    }

    pdfPages.sort((a, b) => a.page_number - b.page_number);

    function getPercentageIncreaseDecrease(newNum, origNum) {
        return ((newNum - origNum) / origNum) * 100;
    }
    const [pageNumber, setPageNumber] = useState(1);

 

    return (
        <>
             <Box ref={pdfEditorRef} sx={{bgcolor: 'lightgray'}} display="flex" justifyContent="center" alignItems="center">
                <Document 
                    file={file_url}
                    loading={isLoading}
                    onLoadSuccess={handleOnDocumentLoadSuccess}
                >
                    <Stack direction="column" justifyContent="space-evenly" alignItems="center" >
                        {pdfPages.map((page, i) => {
                            let pdfPageOrigWidth = page.page._pageInfo.view[2]
                            let pdfPageOrigHeight = page.page._pageInfo.view[3]
                            console.log("page nnnn", page);
                            console.log("pdfPageOrigWidth nnnn", pdfPageOrigWidth);
                            return(
                                <PDFPageComponent pdfPageOrigWidth={pdfPageOrigWidth} pdfPageOrigHeight={pdfPageOrigHeight} adjustedPDFWidth={adjustedPDFWidth} template_code_queryParam={template_code_queryParam} templateProperties={templateProperties} numPages={page.numPages}
                                        page_number={page.page_number} elements={elements} setElements={setElements} setTemplatePropertiesPayload={setTemplatePropertiesPayload}
                                        setDeletedPropertyIdsPayload={setDeletedPropertyIdsPayload} isViewing={isViewing} 
                                        templatePropertiesPayload={templatePropertiesPayload} canConfigTemplate={canConfigTemplate} setHasSeal={setHasSeal}
                                        userSignatureUpdated={userSignatureUpdated} setUserSignatureUpdated={setUserSignatureUpdated} deletedElements={deletedElements} setDeletedElements={setDeletedElements} signatureUpdated={signatureUpdated}
                                        setSignatureUpdated={setSignatureUpdated} signatureDeleted={signatureDeleted} setSignatureDeleted={setSignatureDeleted}
                                        getB2CProfile={getB2CProfile} saveTemplateElementToCache={saveTemplateElementToCache}
                                        currentSelectedUser={currentSelectedUser} setCurrentSelectedUser={setCurrentSelectedUser}
                                    />
                            )
                        })}
                    </Stack>
                </Document>
            </Box>
        </>
    )
}

export default PDFBoxComponent