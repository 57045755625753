import { Stack, FormControlLabel, Switch, Divider, Tooltip, Button, Card, CardContent, Box, Grid, IconButton, InputAdornment, TextField, Typography, ButtonGroup, Chip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { ThreeDots } from 'react-loader-spinner';
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Avatar from 'react-avatar';
import useProjects from "../../hooks/useProjects";
import ProjectDetailsSkeletonComponent from "../skeletons/project-details-skeleton.component";
import { useNavigate } from "react-router-dom";
import useBusiness from "../../hooks/useBusiness";
import ProjectPopperComponent from "./project-popper.component";
import PulseLoader from "react-spinners/PulseLoader";
import useSignature from "../../hooks/useSignature";
import useAutoRevoke from "../../hooks/useAutoRevoke";


function ProjectDetailsComponent(props) {

    let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

    const { projectId, projectCredit, project_name } = props;

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();


    const { viewProjectDetails, projectDetails, isLoadingDetails, regenerateAPIKey, isRegeneratingAPIKey, regeneratedAPIKey,
        isUpdatingDownloadStatus,  updateDownloadable, isUpdatingPolicy, updatePolicy } = useProjects();
    const { getBusinessById, businessDetails, fetchingBusinessDetails } = useBusiness();
    const {getTemplateSignatureLimit, templateSignatureLimit, configureSignatureLimit, isConfiguringSignatureLimit, configuredSignatureLimit} = useSignature();
    const {
        isConfigAutoRevoke, 
        isDeletingAutoRevoke, 
        configAutoRevoke, 
        deleteAutoRevoke, 
        isAutoRevokeConfigured, 
        resetAutoRevokeConfigured,
        retrieveAutoRevoke
    } = useAutoRevoke();

    const [expirationDateUpdate, setExpirationDateUpdated] = useState(false);
    const [updatedExpirationDate, setExpirationDate] = useState("");
    const [apiKeyRegenerated, setApiKeyRegenerated] = useState(false);
    const [showApiKey, setApiKey] = useState(false);
    const [showSubKey, setSubKey] = useState(false);
    const [signatureLimit, setSignatureLimit] = useState(0);
    const [downlodable, setDownloadable] = useState(projectDetails?.is_data_downloadable);
    const [privacy, setPrivacy] = useState(projectDetails?.privacy);
    const [revokeDurationDays, setRevokeDurationDays] = useState(0);
    const [showSubscriptionKey, setSubscriptionKey] = useState(false);
    const [isSetAutoRevoke, setSetAutoRevoke] = useState(false);
    const [isResetAutoRevoke, setResetAutoRevoke] = useState(false);


    const handleClickShow = (e) => { setApiKey(!showApiKey) }
    const handleClickShowSub = (e) => { setSubKey(!showSubKey) }

    useEffect(() => { viewProjectDetails(projectId);  retrieveAutoRevoke(projectId); }, [projectId])
    useEffect(() => { getBusinessById(businessIdFromURL); }, [businessIdFromURL]);

    const utcTime = new Date(projectDetails?.created_date);
    const options = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
    const localTime = utcTime.toLocaleString('en-US', options);


    useEffect(() => {
        if(projectDetails){
            setPrivacy(projectDetails?.privacy);
            setDownloadable(projectDetails?.is_data_downloadable);
        }
    }, [projectDetails]);

    useEffect(() => {
        if (privacy === "PRIVATE") {
            setDownloadable("0");
        }
    }, [privacy]);

    useEffect(()=>{
        getTemplateSignatureLimit(projectId)
    },[])

    useEffect(() => {
        if (templateSignatureLimit) {
            if (templateSignatureLimit.data) setSignatureLimit(templateSignatureLimit.data)
        }
    }, [JSON.stringify(templateSignatureLimit)])

    useEffect(() => {
        if (configuredSignatureLimit) getTemplateSignatureLimit(projectId)
    }, [JSON.stringify(configuredSignatureLimit)])

    useEffect(() => {
        const revokeDurationFromLocalStorage = localStorage.getItem("revokeDuration");
        const autoRevokeRecordFromLocalStorage = localStorage.getItem("autoRevokeRecord");

        console.log("autoRevokeRecordFromLocalStoragexx",autoRevokeRecordFromLocalStorage)
        console.log("revokeDurationFromLocalStoragexxx",revokeDurationFromLocalStorage)

        setRevokeDurationDays(revokeDurationFromLocalStorage);

        if (autoRevokeRecordFromLocalStorage === "true") {
            setSetAutoRevoke(true);
            setResetAutoRevoke(false);

        } else if (autoRevokeRecordFromLocalStorage === "false") {
            setSetAutoRevoke(false);
            setResetAutoRevoke(true);
        }
    }, [isAutoRevokeConfigured, resetAutoRevokeConfigured])

    const handleCopyToClipboard = (e, name, key) => {
        navigator.clipboard.writeText(key);

        if (name == "apiKey") {
            enqueueSnackbar("Successfully copied API Key to clipboard", {
                variant: "success",
                autoHideDuration: 3000
            });

        } else {
            enqueueSnackbar("Successfully copied Subscription Key to clipboard", {
                variant: "success",
                autoHideDuration: 3000
            });
        }
    }

    const handleRegenerate = (e) => {
        regenerateAPIKey(projectId, setApiKeyRegenerated); 
    }

    console.log("projectDetailsxxx", projectDetails)

    const handleOnChangeSignatureLimit = (e) => {
        const input = e.target.value;

        if (input < 0 || input === "") {
            setSignatureLimit(0);

        } else if (input > 10) {
            setSignatureLimit(10);

        } else {
            setSignatureLimit(input);
        }
    }


    const handleSaveSignatureLimit = (e) => {
        if (signatureLimit > 10) {
            let modifiedSignatureLimit = 10;

            configureSignatureLimit(projectId, modifiedSignatureLimit);

        } else {
            configureSignatureLimit(projectId, signatureLimit);
        }
    }

    const handleToggleDownloadable = () => {
        let isDownloadable = downlodable == '0' || downlodable == null ? '1' : '0';
        setDownloadable(isDownloadable)

        let parameters = {
            id: projectDetails?.id,
            isDownloadable: isDownloadable
        }

        updateDownloadable(parameters)
    }

    const handleToggle = () => {
        let newPrivacy = privacy === 'PUBLIC' ? 'PRIVATE' : 'PUBLIC';
        setPrivacy(newPrivacy);

        let payload = {
            privacy: newPrivacy
        }
        updatePolicy(projectDetails?.id, payload)
    };

    const handleDurationChange = (e) => {
        if (e.target.value < 0) {
            setRevokeDurationDays(0);

        } else if (e.target.value > 1000) {
            setRevokeDurationDays(1000);

        } else {
            setRevokeDurationDays(e.target.value);
        }
    }

    const handleSetRevokeDuration = (e) => {
        if (revokeDurationDays > 0) {
            configAutoRevoke(projectDetails?.id, revokeDurationDays);

        } else {
            enqueueSnackbar("Minimum revoke duration is 1 day only", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleDeleteRevokeDuration = (e) => {
        deleteAutoRevoke(projectDetails?.id);
    }

    return (

        <Box>

            <Card sx={{ borderRadius: 5, boxShadow: 'none', padding: 4, pt: 1 }}>

                <CardContent>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={9} sm={9} sx={{display:'flex'}}>
                            {projectDetails?.logo ?
                                <img alt="logo-img" src={projectDetails.logo} style={{ height: 100, borderRadius: 5, objectFit: "contain" }} />
                            :
                                <Box>
                                    <Avatar round={true} name={projectDetails?.application_name} maxInitials={2} />
                                </Box>
                            }
                        </Grid>

                        <Grid xs={3} sm={3} sx={{justifyContent:'end', display: "flex"}}>
                            <ProjectPopperComponent 
                                project={projectDetails}
                                setExpirationDateUpdated={setExpirationDateUpdated} 
                                setExpirationDate={setExpirationDate}
                                expirationDateUpdate={expirationDateUpdate}
                                updatedExpirationDate={updatedExpirationDate}
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Name:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <Typography>
                                {projectDetails?.application_name}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Description:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            {projectDetails?.description}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Last Date Modified:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            {localTime}
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ alignSelf: 'center' }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Expiration Date:
                            </Typography>
                        </Grid>


                        <Grid item xs={12} sm={8}>
                            {expirationDateUpdate ?
                               
                               <Typography sx={{color: updatedExpirationDate == null ? "green" : null}}>
                                   {updatedExpirationDate == null ? "No Expiration" : updatedExpirationDate &&  format(new Date(updatedExpirationDate), 'PP') }
                               </Typography>
                               
                           :
                               <Typography sx={{color: projectDetails?.project_expiration == null ? "green" : null}}>
                                   {projectDetails?.project_expiration == null ? "No Expiration" : format(new Date(projectDetails?.project_expiration), 'PP') }
                               </Typography>
                           }
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Status:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            {projectDetails?.active == false ?
                                <Typography sx={{ color: "red" }} >Inactive</Typography>
                                :
                                <Typography sx={{ color: "green" }} >Active</Typography>
                            }
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Credits:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <Chip label={(projectCredit > 0) ? projectCredit + " Credits" : "0 Credit"} color="success" variant="outlined" size="small" />
                        </Grid>

                        <br /><br />

                        <Grid item xs={12}>
                            <Card sx={{ width: '100%', p: 2, textAlign: 'start', justifyContent: 'center', mb: 1, border: '1px solid #80808040' }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    API Credentials
                                </Typography>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            autoComplete="off"
                                            value={businessDetails?.subscription_key}
                                            placeholder="Subscription Key"
                                            type={(showSubKey) ? "text" : "password"}
                                            inputProps={{ sx: { mr: "28px", padding: 1 } }}
                                            InputProps={{
                                                style: { fontSize: 10, paddingRight: 1 },
                                                startAdornment:
                                                    <InputAdornment position="start" >
                                                        <p style={{ fontWeight: 'bold', fontSize: 12 }}>Subscription Key:</p>
                                                    </InputAdornment>,
                                                endAdornment: (
                                                    <InputAdornment position="end" >
                                                        <ButtonGroup variant="text" aria-label="text button group">

                                                            <IconButton onClick={(e) => handleClickShowSub(e)} aria-label="toggle password visibility" edge="end" sx={{ mr: 0 }} >
                                                                {(showSubKey) ?
                                                                    <Tooltip title="Hide" placement="top" arrow>
                                                                        <Visibility fontSize="small" />
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title="Show" placement="top" arrow>
                                                                        <VisibilityOff fontSize="small" />
                                                                    </Tooltip>
                                                                }
                                                            </IconButton>

                                                            <Divider orientation="vertical" sx={{ height: "auto" }} />

                                                            <Tooltip title="Copy" placement="top" arrow>
                                                                <IconButton sx={{ textTransform: "none", fontSize: 10 }}
                                                                    onClick={(e) => handleCopyToClipboard(e, "subKey", businessDetails?.subscription_key)}
                                                                >
                                                                    <ContentCopyIcon sx={{ width: 18, height: 18, color: "#0178f2" }} />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </ButtonGroup>
                                                    </InputAdornment>
                                                ),
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10} >
                                        {(isRegeneratingAPIKey) ?
                                            <Grid sx={{display: "flex", width: "100%", height: "60px", borderRadius: "4px", paddingLeft: 0}}>
                                                <span style={{fontSize: "14px", color: "#0178f2"}}>
                                                    Regenerating
                                                </span>

                                                <div style={{paddingLeft: "3px", paddingTop: "1px"}}>
                                                    <PulseLoader color="#0178f2" size="6" />
                                                </div>
                                            </Grid>

                                            : 
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    value={(apiKeyRegenerated) ? regeneratedAPIKey : projectDetails?.api_key} 
                                                    placeholder="Password"
                                                    type={(showApiKey) ? "text" : "password"}
                                                    inputProps={{sx: {mr: "28px", padding: 1}}}
                                                    InputProps={{
                                                        style: {fontSize: 10, paddingRight: 1},
                                                        startAdornment: 
                                                            <InputAdornment position="start" >
                                                                <p style={{fontWeight:'bold', fontSize: 12}}>API Key:</p>
                                                            </InputAdornment>,
                                                        endAdornment: (
                                                            <InputAdornment position="end" style={{marginLeft: -29}}>
                                                                <ButtonGroup variant="text" aria-label="text button group">
                                                                    <IconButton onClick={(e) => handleClickShow(e)} aria-label="toggle password visibility" edge="end" sx={{mr: 0}}>
                                                                        {(showApiKey) ? 
                                                                            <Tooltip title="Hide" placement="top" arrow>
                                                                                <Visibility fontSize="small" />
                                                                            </Tooltip> 
                                                                        :
                                                                            <Tooltip title="Show" placement="top" arrow>
                                                                                <VisibilityOff fontSize="small" />
                                                                            </Tooltip>
                                                                        } 
                                                                    </IconButton>

                                                                    <Divider orientation="vertical" sx={{height: "auto"}} /> 
                                                                
                                                                    <Tooltip title="Copy" placement="top" arrow>
                                                                        <IconButton sx={{textTransform: "none", fontSize: 10}}
                                                                            onClick={(e) => handleCopyToClipboard(e, "apiKey", projectDetails?.api_key)}
                                                                        >
                                                                            <ContentCopyIcon sx={{width: 18, height: 18, color: "#0178f2"}} />
                                                                        </IconButton>
                                                                    </Tooltip>   
                                                                </ButtonGroup>
                                                            </InputAdornment>
                                                        )
                                                    }} 
                                                />
                                        }
                                            
                                    </Grid>
                                    <Grid item xs={2} sx={{textAlign:'start'}} >
                                        <Button sx={{textTransform: "none", fontSize: 12, display: (isRegeneratingAPIKey) ? "none" : "visible"}} onClick={(e) => handleRegenerate(e)}>
                                            Regenerate
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#0178f2', fontSize: 14 }}>
                                Verification Settings
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} sx={{ mt: 1.5, mb: 1 }}>
                            <Divider sx={{ color: 'blue' }} />
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ alignSelf: "center", display: 'flex' }}>
                            <Typography sx={{ fontWeight: "bold", alignSelf: 'center', fontSize: 14 }}>
                                Privacy
                            </Typography>

                            <Tooltip title={
                                <Typography sx={{ fontSize: 12 }} >
                                    Setting the privacy to "PRIVATE" allows the verifier to only check the owner and authencity of the file, without accessing any information about it's contents.
                                </Typography>
                            } placement="top-start" sx={{ color: '#0178f2' }} >
                                <IconButton>
                                    <InfoIcon sx={{ alignSelf: 'center', color: '#0178f2' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={8} sx={{ alignSelf: "center" }}>
                            {isUpdatingPolicy ?
                                <Grid sx={{display: "flex"}}>
                                    <Grid style={{marginTop: -2}}>
                                        <ThreeDots height="25" width="25" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                                    </Grid>

                                    <Typography sx={{ml: "6px", color: "#0178f2"}}>
                                        Updating Privacy
                                    </Typography>
                                </Grid>
                            : 
                                <FormControlLabel
                                    control={<Switch checked={privacy === 'PRIVATE'} onChange={handleToggle} />}
                                    label={privacy} 
                                />
                            }
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ alignSelf: "center", display: 'flex' }}>
                            <Typography sx={{ fontWeight: "bold", alignSelf: 'center', fontSize: 14 }}>
                                Enable Download
                            </Typography>
                            <Tooltip title={
                                <Typography sx={{ fontSize: 12 }} >
                                    Setting the toggle option to "No" means the verifier can still verify the authenticity of the file without downloading it.
                                </Typography>
                            } placement="top-start" sx={{ color: '#0178f2' }} >
                                <IconButton>
                                    <InfoIcon sx={{ alignSelf: 'center', color: '#0178f2' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={8} sx={{ alignSelf: "center" }}>
                            {(privacy === "PUBLIC") ?
                                <div>
                                    {(isUpdatingDownloadStatus) ?
                                        <Grid sx={{display: "flex"}}>
                                            <Grid style={{marginTop: -2}}>
                                                <ThreeDots height="25" width="25" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                                            </Grid>
    
                                            <Typography sx={{ml: "6px", color: "#0178f2"}}>
                                                {(downlodable == "0" || downlodable == null) ? "Disabling download" : "Enabling download"}
                                            </Typography>
                                        </Grid>
                                    :

                                        <FormControlLabel
                                            control={<Switch checked={downlodable == '1'} onChange={handleToggleDownloadable} />}
                                            label={downlodable == '0' || downlodable == null  ? "NO" : "YES"}
                                        />
                                    }
                                </div>
                                
                            :
                                <FormControlLabel control={<Switch disabled />} label={"NO"} />
                            }
                        </Grid>

                        <Grid container sx={{ml: 2, mt: "16px"}}>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignSelf: "center", display: "flex", mt: 1}}>
                                <Grid item xs={6} sm={6} md={4} lg={4} xl={4} sx={{alignSelf: 'center'}} >
                                    <Typography sx={{ fontWeight: "bold", alignSelf: 'center', fontSize: 14 }}>
                                        Configure Auto Revoke 
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sm={2} md={4} lg={4} xl={4} sx={{display: "flex"}}>
                                    <TextField
                                        disabled={isSetAutoRevoke ? false : true}
                                        size="small"
                                        type="number"
                                        inputProps={{inputMode: "numeric", pattern: "[0-9]*"}} 
                                        InputProps={{
                                            inputProps: { 
                                                max: 1000, min: 1
                                            },
                                        }}
                                        value={revokeDurationDays}
                                        onChange={(e) => handleDurationChange(e)}
                                        className="custom-disabled-textfield"
                                        sx={{
                                            "& .MuiInputBase-root": {height: 32, width: 75},                                        
                                        }}
                                    /> 
                                    
                                    <div style={{marginTop: "8px", marginLeft: "8px"}}>
                                        days
                                    </div>

                                    {(isSetAutoRevoke) &&
                                        <Button variant="text" sx={{textTransform: "none"}}
                                            startIcon={isDeletingAutoRevoke && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                            onClick={(e) => handleDeleteRevokeDuration(e)}
                                        >
                                            {(isDeletingAutoRevoke) ? "Resetting Revoke Duration": "Reset"}
                                        </Button>
                                    }

                                    {(isResetAutoRevoke) && 
                                        <Button variant="text" sx={{textTransform: "none", ml: 2}}
                                            startIcon={isConfigAutoRevoke && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                            onClick={(e) => handleSetRevokeDuration(e)}
                                        >
                                            {(isConfigAutoRevoke) ? "Setting Revoke Duration" : "Save"}
                                        </Button>
                                    }
                               </Grid>
                              
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} sx={{mt: 2}}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#0178f2', fontSize: 14 }}>
                                Template Settings
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12} sm={12}><Divider sx={{color:'blue'}} /></Grid>
                        <Grid container sx={{ml: 2, mt: "16px"}}>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignSelf: "center", display: "flex", mt: 1}}>
                                <Grid item xs={6} sm={6} md={4} lg={4} xl={4} sx={{alignSelf: 'center'}} >
                                    <Typography sx={{fontWeight: "bold", alignSelf: "center"}}>
                                        Set Signature Limit
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sm={2} md={4} lg={4} xl={4} sx={{display: "flex"}}>
                                    <TextField
                                        size="small"
                                        type="number"
                                        inputProps={{inputMode: "numeric", pattern: "[0-9]*"}} 
                                        InputProps={{
                                            inputProps: { 
                                                min: 0, max: 10
                                            },
                                        }}
                                        value={signatureLimit}
                                        onChange={(e) => handleOnChangeSignatureLimit(e)}
                                        className="custom-disabled-textfield"
                                        sx={{
                                            "& .MuiInputBase-root": {height: 32, width: 75},                                        
                                        }}
                                    />
            
                                    <Button 
                                        startIcon={isConfiguringSignatureLimit && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                        sx={{textTransform: "none", ml: 1}} onClick={(e) => handleSaveSignatureLimit(e)} >
                                            {(isConfiguringSignatureLimit) ? "Saving" : "Save"}
                                    </Button>
                               </Grid>
                            </Grid>
                        </Grid>
                        
                        
                    </Grid>

                </CardContent>
            </Card>
        </Box>
    )
}
export default ProjectDetailsComponent