import { ListItemAvatar, Avatar, Box, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Stack, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../hooks/useBusiness";
import ReactSelect from 'react-select';
import useReports from "../../hooks/useReports";
import ReportOneSkeletonComponent from "../skeletons/report-one-skeleton-component";
import { stringAvatar } from "../../utils/stringAvatarUtil";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import CachedIcon from '@mui/icons-material/Cached';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";



function encrypt(text, key) {
    let result = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        result += String.fromCharCode(charCode);
    }
    return btoa(result);
}

const ReportOneTableHeader = () => {

    const tablecell = [
        { label: 'Logo' },
        { label: 'Project Name' },
        { label: 'No. of Authentication' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ReportOneTableContent = (props) => {

    const { project, breakpoints, dateFrom, dateTo, formattedDateRange } = props;

    console.log("formattedDateRange xxx one", formattedDateRange)

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                {(project && project?.project?.logo) ?
                    <Avatar variant="square" src={project?.project?.logo ? project?.project?.logo : null}
                        sx={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 50, height: 50, objectFit: "contain" }}
                        imgProps={{ sx: { objectFit: "contain" } }}
                    />
                    :
                    <ListItemAvatar>
                        <Avatar {...stringAvatar(project && project?.project.name)} style={{ height: 50, width: 50 }} />
                    </ListItemAvatar>
                }
            </TableCell>
            <TableCell component="th" scope="row"
                sx={{ cursor: 'pointer', color: "#0178f2" }}
                onClick={() => {
                    const projectId = project?.project?.project_id;
                    const businessId = project?.project?.business_id;
                    const encryptedFormattedDateRange = encrypt(formattedDateRange, 'ovcode');
                    window.location.href = `/all-reports-two?projectId=${projectId}&businessId=${businessId}&data=${encryptedFormattedDateRange}`;
                }}
                
            >
                {project && project?.project?.name}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {project && project?.total_authenticated_count}
            </TableCell>

        </TableRow>
    )
}



const ReportsComponent = (props) => {

    const { setForbidden } = props;

    // Check if there's a "businessId" parameter in the URL
    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("businessId");

    const { getBusinessListNames, business_names, isFetchingBusinessNames, getBusinessById, businessDetails } = useBusiness();
    const { loading, generateReportOne, total_authentication, reportDataOne, error } = useReports();

    useEffect(() => { getBusinessListNames(); }, [])

    useEffect(() => {

        if (businessIdFromURL) {
            getBusinessById(businessIdFromURL);
        }

    }, [businessIdFromURL])

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Note: getMonth() returns 0-11, not 1-12

    const [options, setOption] = useState([]);
    const [business_id, setBusinessId] = useState(null);
    const [selected_business, setSelectedBusiness] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);

    useEffect(() => {
        if (businessDetails && businessDetails.business_name) {
            setBusinessName(businessDetails.business_name);
        }
    }, [businessDetails]);

    useEffect(() => {
        console.log("error here xx", error)
        if(error){
            setForbidden(true);
        }
    },[error])


    useEffect(() => {

        let options = []

        business_names?.map((business) => {

            let options_data = { value: business?.id, label: business?.business_name }

            options.push(options_data)
        })

        setOption(options)

    }, [JSON.stringify(business_names)])

    const getBusiness = (value) => {
        setBusinessId(value.value);
        setSelectedBusiness(value.label);

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("businessId", value.value);

        window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`);
    };

    useEffect(() => {
        if ((business_id || businessIdFromURL) && dateFrom && dateTo) {
            const businessToUse = business_id || businessIdFromURL;
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");

            generateReportOne(businessToUse,  date_from, date_to);
        }
    }, [business_id, businessIdFromURL, dateFrom, dateTo]);




    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    const reload = () => {

        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)
  
        let date_from = dayjs(reload_dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(reload_dateTo).format("YYYY-MM-DD");
        
        generateReportOne(businessToUse, date_from, date_to);
    }

    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const optionsDate = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", optionsDate);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }


    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
    
        const business = selected_business ? selected_business : businessName;
        const title = `${business} Report as of ${formattedDateRange}`;
    
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = pageWidth / 100;
        const titleY = 10;
    
        doc.text(title, titleX, titleY);
    
        const columns = ["Logo", "Project Name", "No. of Authentication"];
        const columnWidths = ["auto", "*", "auto"]; // Adjust column widths as needed
    
        const data = reportDataOne.map(item => {
            const logoDataUrl = item?.project?.logo;
            return [logoDataUrl, item?.project?.name, item?.total_authenticated_count];
        });
    
        doc.setFontSize(12);
    
        doc.autoTable({
            head: [columns],
            body: data,
            startY: 20,
            columnStyles: {
                0: { cellWidth: 20 }, // Adjust logo column width
                1: { cellWidth: 'auto' }, // Allow auto width for project name
                2: { cellWidth: 'auto' } // Allow auto width for authentication count
            },
            willDrawCell: (hookData) => {
                if (hookData.section === 'body' && columns[hookData.column.index] === 'Logo') {
                    hookData.cell.text = '';
                }
            },
            didDrawCell: (hookData) => {
                if (hookData.section === 'body' && columns[hookData.column.index] === 'Logo') {
                    const logoDataUrl = data[hookData.row.index][hookData.column.index];
    
                    if (logoDataUrl) {
                        const imgWidth = 10;
                        const imgHeight = 10;
                        doc.addImage(logoDataUrl, 'JPEG', hookData.cell.x + 1, hookData.cell.y + 1, imgWidth, imgHeight);
                    }
                }
            },
        });
    
        const overallTotal = total_authentication ? total_authentication : 0;
    
        const textWidth = doc.getStringUnitWidth(`Total Authentication: ${overallTotal}`) * 12;
        const textX = pageWidth - textWidth - 10;
        const textY = doc.autoTable.previous.finalY + 10;
    
        doc.text(`Total Authentication: ${overallTotal}`, textX, textY);
    
        doc.save(`${selected_business ? selected_business : businessName}-Document-Report.pdf`);
    };
    
    const businessToUse = business_id || businessIdFromURL;

    console.log("reportDataOnexxx",reportDataOne)


    return (
        <Box>
            <Grid container spacing={0} sx={{ zIndex: 4, position: 'relative' }}>
                <Grid item xs={4} sx={{ justifyContent: 'flex-start' }}>
                    {isFetchingBusinessNames ?
                        <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                    :
                        <ReactSelect
                            className="basic-single"
                            placeholder={'Select Enterprise'}
                            defaultValue={selected_business ? selected_business : null}
                            isRtl={false}
                            isSearchable={true}
                            options={options}
                            // styles={dataStyles}
                            onChange={getBusiness}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: 38,
                                    borderRadius: 100,
                                    backgroundColor: 'transparent',
                                    // width: 250,
                                    fontSize: 12
                                }),
                                placeholder: base => ({
                                    ...base,
                                    fontSize: 12
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 99999,
                                    fontSize: 12
                                }),
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "end", justifyContent: 'flex-end', ml: 'auto' }}>
                    <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                        <Box>
                            <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateFrom}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12, height: 38 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    borderRadius: 10,
                                                    fontSize: 12,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}
                                            onChange={(newValue) => { setDateFrom(newValue) }}
                                            maxDate={dateTo}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>

                                &nbsp; &nbsp;

                                <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateTo}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    fontSize: 12,
                                                    borderRadius: 10,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}

                                            onChange={(newValue) => { setDateTo(newValue) }}
                                            minDate={dateFrom}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>

                                &nbsp; &nbsp;

                                <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ textAlign: 'center', maxWidth: '15% !important', flexBasis: '15% !important' }}>
                    <Button
                        variant="contained"
                        onClick={generatePDF}
                        sx={{
                            width: 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                            ":hover": { background: "#0178f2" }
                        }}
                        disabled={!businessToUse || reportDataOne?.length === 0 || reportDataOne === undefined ? true : false}
                    >
                        Download
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ mt: 5 }} />

            {selected_business || businessIdFromURL ? (
                <Grid container sx={{ position: 'relative', zIndex: 2 }}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 'bold' }}>{selected_business ? selected_business : businessName}</Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 1 }}>
                            <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                                <ReportOneTableHeader />

                                {loading ?
                                    <ReportOneSkeletonComponent />
                                :
                                    <TableBody>
                                        {(reportDataOne && reportDataOne)?.map((project, index) => (
                                            <ReportOneTableContent 
                                                key={index} 
                                                project={project} 
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                formattedDateRange={formattedDateRange}
                                            />
                                        ))}
                                    </TableBody>
                                }

                            </Table>
                            {!loading && (reportDataOne === undefined || reportDataOne.length === 0) &&
                                <Box sx={{ margin: 10, textAlign: 'center', ml: 'auto', mr: 'auto' }}>
                                    <Typography><b>{selected_business ? selected_business : businessName}</b> has no available authentications as of {formattedDateRange} . </Typography>
                                </Box>
                            }
                        </TableContainer>
                    </Grid>
                    <br />
                    <Grid item xs={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', ml: -2, mt: 1 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Total Authentication : {total_authentication ? total_authentication : 0} </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Box sx={{ margin: 10, textAlign: 'center', ml: 'auto', mr: 'auto' }}>
                    <Typography>Please select Enterprise</Typography>
                </Box>
            )}
        </Box>
    );
};

export default ReportsComponent;
