import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
// import { useHistory } from 'react-router-dom'; // Import useHistory
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import AccountDetailsComponent from "./view-business-account-components/account-details.component";
import AuthenticationComponent from "./view-business-account-components/authentication.component";
import ViewBusinessAccountUsersComponent from "./view-business-account-users.component";
import ProjectListScreen from "../../screens/ProjectListScreen";
import EnterpriseDashboardComponent from "../enterprise/dashboard/enterprise-dashboard.component";
import EnterprisePlanComponent from "../enterprise/plan/enterprise-plan.component";
import EnterpriseReportsComponent from "../enterprise/reports/enterprise-reports.component";
import EnterpriseActivityLogsScreen from "../../screens/EnterpriseActivityLogsScreen";
import EnterpriseTransactionComponent from "../enterprise/enterprise-transactions.component";
import EnterpriseTemplatesComponent from "../enterprise/templates/enterprise-templates.component";
import TemplateManagementComponent from "../enterprise/templates/template-management.component";
import VaultScreen from "../../screens/VaultScreen";
import ProjectManagementScreen from "../../screens/ProjectManagementScreen";
import EnterpriseReportsTabsComponent from "../enterprise/reports/enterprise-reports-tabs.component";
import { Link, useLocation, useNavigate } from "react-router-dom";

function TabPanel(props) {
      
  const { children, value, index, ...other } = props;
  

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
return {
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
};
}

function ViewBusinessAccountTabComponent(props){

  const {breakpoints, businessDetails, fetchingBusinessDetails, setEnterpriseName, businessId} = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [filesPath, setFilesPath] = useState("");
  const [value, setValue] = useState(location.filesPath);

  function handleChange(event, newValue){
    setValue(newValue);
  }

  useEffect(() => {
    let type;
    if (location.pathname == '/view') {
      type = 0
    } else if (location.pathname == '/view/details') {
      type = 1
    } else if (location.pathname == '/view/projects') {
      type = 2
    } else if (location.pathname == '/view/vault') {
      type = 3
    } else if (location.pathname == '/view/authentication') {
      type = 4
    } else if (location.pathname == '/view/templates') {
      type = 5
    } else if (location.pathname == '/view/analytics') {
      type = 6
    } else if (location.pathname == '/view/users') {
      type = 7
    } else if (location.pathname == '/view/reports') {
      type = 8
    } else if (location.pathname == '/view/plan') {
      type = 9
    } else if (location.pathname == '/view/activity-logs') {
      type = 10
    }
    setFilesPath(type)
  }, [location.pathname])

  useEffect(() => {
    setValue(filesPath)
  }, [filesPath])

  const tabLabels = [
    { text: 'Dashboard', path: `/view?id=${businessId}` },
    { text: 'Details', path: `/view/details?id=${businessId}` },
    { text: 'Projects', path: `/view/projects?id=${businessId}` },
    { text: 'Vault', path: `/view/vault?id=${businessId}` },
    { text: 'Authentication', path: `/view/authentication?id=${businessId}` },
    { text: 'Templates', path: `/view/templates?id=${businessId}` },
    { text: 'Analytics', path: `/view/analytics?id=${businessId}` },
    { text: 'Users', path: `/view/users?id=${businessId}` },
    { text: 'Reports', path: `/view/reports?id=${businessId}` },
    { text: 'Plan', path: `/view/plan?id=${businessId}` },
    { text: 'Activity Logs', path: `/view/activity-logs?id=${businessId}` },
  ];

  const getTabLabel = (text) => (
    <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'transparent', pt: 0, pb: 0 }} >
      <ListItem sx={{ pt: 0, pb: 0 }}>
        <ListItemText
          primary={<Typography sx={{ textAlign: 'left', color: '#000', fontWeight: "bold", fontSize: 14 }}>{text}</Typography>}
        />
      </ListItem>
    </List>
  );

  return(
        <Box>
            <CustomTabs
                variant= "scrollable"
                value={value} 
                onChange={handleChange} 
                sx={{marginTop: 2}} 
                indicatorColor="transparent"
                centered
            >
              {tabLabels.map((tab, index) => (
                <CustomTab
                  key={index} value={value}
                  label={getTabLabel(tab.text)}
                  to={tab.path}
                  component={Link}
                  {...a11yProps(index)}
                  sx={{ alignItems: 'flex-start !important', mb: 1 }}
                />
              ))}
          </CustomTabs>

          <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={0} >
            <EnterpriseDashboardComponent />
          </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={1} >
              <AccountDetailsComponent setEnterpriseName={setEnterpriseName} />
            </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={2} >
              <ProjectManagementScreen />
            </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={3} >
              <VaultScreen />
            </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={4} >
              <AuthenticationComponent/>
            </TabPanel>

          <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={5} >
            <TemplateManagementComponent />
          </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={6} >
              <EnterpriseTransactionComponent />
            </TabPanel>

          <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={7} >
            <ViewBusinessAccountUsersComponent />
          </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={8} >
              <EnterpriseReportsTabsComponent/>
            </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={9} >
              <EnterprisePlanComponent />
            </TabPanel>

            <TabPanel style={{padding: "0px", width: '100%'}} value={value} index={10} >
              <EnterpriseActivityLogsScreen />
            </TabPanel>
        </Box>
  )
}

export default ViewBusinessAccountTabComponent;
