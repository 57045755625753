import {Box, Button} from "@mui/material";
import ViewBusinessAccountComponent from "../components/account/view-business-account.component";
import BackButton from "../components/common/back-button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useBusiness from "../hooks/useBusiness";
import ViewBusinessAccountTabComponent from "../components/account/view-business-account.tab.component";

const AccountManagementViewScreen = () => {

    const navigate = useNavigate();
    const [enterprise_name, setEnterpriseName] = useState('');

    // let businessId = (new URLSearchParams(window.location.search)).get("id");

    const { getBusinessById, businessDetails, fetchingBusinessDetails } = useBusiness();

    useEffect(() => {
        const businessId = (new URLSearchParams(window.location.search)).get("id");
        getBusinessById(businessId);
    }, [])


    console.log("businessDetailsxxx",businessDetails)


    return(
         <Box>
    
             <Box sx={{textAlign: 'start', pb: 2, mt: -1.5}}>
                <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                    style={{color: "black", fontSize: 16, textTransform: 'none', fontWeight : 600  }}
                    onClick={() => navigate('/account-management') } >
                    {businessDetails?.business_name}
                </Button>
            </Box>

            <ViewBusinessAccountTabComponent setEnterpriseName={setEnterpriseName} businessId={businessDetails?.id} />
        </Box>
    )
}

export default AccountManagementViewScreen