import { useState } from "react";
import { useSnackbar } from "notistack";
import RolesManagementService from "../services/api/roles-management";


const useRolesManagement = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [deletingRole, setDeletingRole] = useState(false);
    const [updatingRole, setUpdatingRole] = useState(false);
    const [updatingRoleStatus, setUpdatingRoleStatus] = useState(false);
    const [creatingRole, setCreatingRole] = useState(false);
    const [deletedRole, setDeletedRole] = useState(false);
    const [createdRole, setCreatedRole] = useState(false);
    const [updatedRole, setUpdatedRole] = useState(false);
    const [updatedRoleStatus, setUpdatedRoleStatus] = useState(false);
    const [inheritanceChildren, setInheritanceChildren] = useState([]);
    const [inheritanceRole, setInheritanceRole] = useState('');
    const [addingInheritance, setAddingInheritance] = useState(false);
    const [addedInheritance, setAddedInheritance] = useState(false);
    const [removingInheritance, setRemovingInheritance] = useState(false);
    const [removedInheritance, setRemovedInheritance] = useState(false);
    const [active, setActive] = useState(false);
    const [pagination, setPagination] = useState(0);
    const [all_list, setAllList] = useState(0);
    const [retrieved, setRetrieved] = useState(false);

    const [isSearchingRoles, setSearchingRoles] = useState(false);
    const [searchedRoles, setSearchedRoles] = useState([]);
    const [isSearchRolesToggle, setSearchRolesToggle] = useState(false);
    const [isSearchedResultsEmpty, setSearchedResultsEmpty] = useState(false);
    const [enterpriseRoleList, setEnterpriseRoleList] = useState(null);
    const [isFetchingRoles, setFetchingRoles] = useState(false);
    const [error, setError] = useState('');

    var limit = 10;
    var rolesLimit = 200;

    
    const getRoles = (page) => {
        setIsLoading(true)
        RolesManagementService.getRoles(page, limit).then((res)=>{

            console.log("getRoles data xxxx", res);

            setIsLoading(false)

            if(res.data.success){
                setRolesList(res.data.data)
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(all_list / limit); //set it depending on ui return - 18
                    setPagination(last_page)
                  }
            }

           
        }).catch(()=>{
            setIsLoading(false)
        })
    };


    const getRolesSelect = (page) => {
        setIsLoading(true)
        RolesManagementService.getRoles(page, rolesLimit).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setRolesList(res.data.data)
            }

           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const getAllRoles = () => {
        setIsLoading(true);
        setFetchingRoles(true);
        setSearchRolesToggle(false);

        RolesManagementService.getAllRoles().then((res)=>{
            

            setIsLoading(false)

            if(res.data.success){
                setRetrieved(true);
                setRolesList(res.data.data)
                setAllList(res.data.data.length);
                setEnterpriseRoleList(res.data.data);;
            }

           
        }).catch((err)=>{
            console.log("getAllRoles data xxxx", err);
            setError(err.response.statusText)
            if (err.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (err.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }
            setIsLoading(false)
            setFetchingRoles(false);
        })
    };


    const createRole = (payload) => {
        setCreatingRole(true)
        RolesManagementService.createRole(payload).then((res)=>{

            setCreatingRole(false)

            if(res.data.success){
                setCreatedRole(true);
                enqueueSnackbar('The role successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setCreatingRole(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const deleteRole = (id) =>{
        setDeletingRole(true)
        RolesManagementService.deleteRole(id).then((res)=>{

            setDeletingRole(false)

            if(res.data.success){
                setDeletedRole(true);
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingRole(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }



    const updateRole = (id, payload) => {
        setUpdatingRole(true)
        RolesManagementService.updateRole(id, payload).then((res)=>{

            setUpdatingRole(false)

            if(res.data.success){
                setUpdatedRole(true);
                enqueueSnackbar('The role successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
               

        }).catch((error) => {

            setUpdatingRole(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }


    const updateRoleStatus = (id, active) => {
        setUpdatingRoleStatus(true)
        RolesManagementService.updateRoleStatus(id, active).then((res)=>{

            console.log("updateRoleStatusxxxx", res )

            setUpdatingRoleStatus(false)

            if(res.data.success){
                setUpdatedRoleStatus(true);
                setActive(res.data.data.active);
                enqueueSnackbar('The role status successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
               

        }).catch((error) => {

            setUpdatingRoleStatus(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }

    const getInheritance = (roleId) => {
        setIsLoading(true)
        RolesManagementService.getInheritance(roleId).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setInheritanceChildren(res.data.data.children)
                setInheritanceRole(res.data.data.role)
            }

           
        }).catch(()=>{
            setIsLoading(false)
        })
    };


    const addInheritance = (payload) => {

        setAddingInheritance(true)
        RolesManagementService.addInheritance(payload).then((res)=>{

            setAddingInheritance(false)

            if(res.data.success){
                setAddedInheritance(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setAddingInheritance(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const removeInheritance = (parentRoleId,roleId) =>{
        setRemovingInheritance(true)
        RolesManagementService.removeInheritance(parentRoleId,roleId).then((res)=>{

            setRemovingInheritance(false)

            if(res.data.success){
                setRemovedInheritance(true);
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setRemovingInheritance(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const searchRoles = (filter, value, page) => {
        setSearchingRoles(true);
        setSearchRolesToggle(true);
        setFetchingRoles(false);

        const search_role_limit = 10;

        RolesManagementService.searchRoles(filter, value, page, search_role_limit).then((res) => {
            console.log("searchRoles data xxxx", res);
          
            if (res.data.success) {
                setSearchingRoles(false);
                setSearchedRoles(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_role_limit); 
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            } 
    
        }).catch((error) => { 
            console.log("searchRoles error xxxx", error);

            setPagination(0);
            setSearchRolesToggle(false);
            setSearchedResultsEmpty(false);
        })
    }


    //ADMIN _ ROLES

    const getAdminRoles = (page) => {
        setIsLoading(true)
        RolesManagementService.getAdminRoles(page, limit).then((res)=>{
            // console.log("getAdminRoles data xxxx", res);

            setIsLoading(false)

            if(res.data.success){
                setRolesList(res.data.data)
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil((all_list + 2) / limit);//set it depending on ui return - 18
                    setPagination(last_page)
                  }
            }

           
        }).catch((err)=>{
            setIsLoading(false)
            setError(err.response.statusText);
        })
    };

    const getAllAdminRoles = () => {
        setIsLoading(true);
        setFetchingRoles(true);
        setSearchRolesToggle(false);

        RolesManagementService.getAllAdminRoles().then((res)=>{
            // console.log("getAllAdminRoles data xxxx", res);

            setIsLoading(false)

            if(res.data.success){
                setRetrieved(true);
                setRolesList(res.data.data)
                setAllList(res.data.data.length);
                setEnterpriseRoleList(res.data.data);
            }

           
        }).catch((err)=>{
            setIsLoading(false)
            setFetchingRoles(false);
            setError(err.response.statusText);
        })
    };


    
    const createAdminRole = (payload) => {
        setCreatingRole(true)
        RolesManagementService.createAdminRole(payload).then((res)=>{

            setCreatingRole(false)

            if(res.data.success){
                setCreatedRole(true);
                enqueueSnackbar('The role successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setCreatingRole(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const deleteAdminRole = (id) =>{
        setDeletingRole(true)
        RolesManagementService.deleteAdminRole(id).then((res)=>{

            setDeletingRole(false)

            if(res.data.success){
                setDeletedRole(true);
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingRole(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }



    const updateAdminRole = (id, payload) => {
        setUpdatingRole(true)
        RolesManagementService.updateAdminRole(id, payload).then((res)=>{

            setUpdatingRole(false)

            if(res.data.success){
                setUpdatedRole(true);
                enqueueSnackbar('The role successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
               

        }).catch((error) => {

            setUpdatingRole(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }


    const updateAdminRoleStatus = (id, active) => {
        setUpdatingRoleStatus(true)
        RolesManagementService.updateAdminRoleStatus(id, active).then((res)=>{

            console.log("updateRoleStatusxxxx", res )

            setUpdatingRoleStatus(false)

            if(res.data.success){
                setUpdatedRoleStatus(true);
                setActive(res.data.data.active);
                enqueueSnackbar('The role status successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
               

        }).catch((error) => {

            setUpdatingRoleStatus(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }

    const getAdminInheritance = (roleId) => {
        setIsLoading(true)
        RolesManagementService.getAdminInheritance(roleId).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setInheritanceChildren(res.data.data.children)
                setInheritanceRole(res.data.data.role)
            }

           
        }).catch(()=>{
            setIsLoading(false)
        })
    };


    const addAdminInheritance = (payload) => {

        setAddingInheritance(true)
        RolesManagementService.addAdminInheritance(payload).then((res)=>{

            setAddingInheritance(false)

            if(res.data.success){
                setAddedInheritance(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setAddingInheritance(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const removeAdminInheritance = (parentRoleId,roleId) =>{
        setRemovingInheritance(true)
        RolesManagementService.removeAdminInheritance(parentRoleId,roleId).then((res)=>{

            setRemovingInheritance(false)

            if(res.data.success){
                setRemovedInheritance(true);
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setRemovingInheritance(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const searchAdminRoles = (filter, value, page) => {
        setSearchingRoles(true);
        setSearchRolesToggle(true);
        setFetchingRoles(false);

        const search_role_limit = 10;

        RolesManagementService.searchAdminRoles(filter, value, page, search_role_limit).then((res) => {
            console.log("searchRoles data xxxx", res);
          
            if (res.data.success) {
                setSearchingRoles(false);
                setSearchedRoles(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_role_limit); 
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            } 
    
        }).catch((error) => { 
            console.log("searchRoles error xxxx", error);

            setPagination(0);
            setSearchRolesToggle(false);
            setSearchedResultsEmpty(false);
        })
    }

    const getAdminRolesSelect = (page) => {
        setIsLoading(true)
        RolesManagementService.getAdminRoles(page, rolesLimit).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setRolesList(res.data.data)
            }

           
        }).catch(()=>{
            setIsLoading(false)
        })
    };


    return{ 
        isLoading, getAllRoles, getRoles, rolesList, createRole, deleteRole, updateRole, updateRoleStatus,
        deletingRole, updatingRole, updatingRoleStatus, updatedRole, creatingRole, deletedRole, 
        createdRole, updatedRoleStatus, getInheritance, inheritanceChildren, inheritanceRole,
        addInheritance, addingInheritance, addedInheritance, removeInheritance, removingInheritance, 
        removedInheritance, active, setIsLoading, pagination, retrieved, getRolesSelect,
        isFetchingRoles, enterpriseRoleList, searchRoles, isSearchingRoles, searchedRoles, isSearchRolesToggle, isSearchedResultsEmpty,
        getAdminRoles, getAllAdminRoles, createAdminRole, deleteAdminRole, updateAdminRole, updateAdminRoleStatus, getAdminInheritance, addAdminInheritance, removeAdminInheritance,
        searchAdminRoles, getAdminRolesSelect, error
    }
};

export default useRolesManagement