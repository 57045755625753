import {Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import { useSnackbar } from "notistack";
import copy from "copy-to-clipboard"; 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from "@mui/icons-material/Close";

const UserPasswordDialog = (props) => {

    const breakpoints = useWidth();
    const { enqueueSnackbar } = useSnackbar();

    const {password, handleClose, openDialog} = props;

    let copyText =  password;

    const copyToClipboard = () => {
        console.log("copy text here :", copyText)
        copy(copyText);
        enqueueSnackbar("Successfully copied to clipboard.", { 
            variant: "success",
            autoHideDuration: 2000
        });
    }


    return (
        <Dialog open={openDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2, margin: 'auto'}}}>
            <DialogTitle sx={{display: "flex", fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "0.875rem" : "18px", padding: "16px 24px 0px 24px"}}>
                User Password

                <Box sx={{ml: "auto"}}>
                    <CloseIcon size="small" onClick={handleClose} 
                        style={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 18 : 22, 
                            color: "#808080a8", marginTop: "0px", cursor: "pointer"
                        }} 
                    />
                </Box>
            </DialogTitle>
           
            <Divider />

            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? 3 : 6, paddingTop: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Please be advised that the password displayed on the screen is available for one-time viewing only. 
                            To ensure the security of the account and sensitive information, we recommend copying the password immediately 
                            and storing it in a secure location. Once you navigate away from this page, the password will no longer be visible.
                            Thank you for your understanding and cooperation.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: "bold"}}> 
                            Password
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{pt: "5px !important"}}>
                        <TextField  
                            fullWidth  
                            variant="outlined"  
                            autoComplete="off"  
                            value={password}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginLeft: -29}}>
                                        <Tooltip title="Copy" placement="top" arrow>
                                            <IconButton onClick={(e) => copyToClipboard(e)} sx={{color: "#0178f2"}} edge="end">
                                                <ContentCopyIcon /> 
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default UserPasswordDialog