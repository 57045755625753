import React, { useState, useEffect, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import Skeleton from "@mui/material/Skeleton";
import useUsers from "../../../hooks/useUsers";
import useWidth from "../../../hooks/useWidth";
import useRolesManagement from "../../../hooks/useRolesManagement";
import useProjects from "../../../hooks/useProjects";
import useBusiness from "../../../hooks/useBusiness";
import UserPasswordDialog from "./user-password.dialog";
import ReactSelect from "react-select";


const CreateUserDialog = ({ openAddDialog, handleCloseAddDialog }) => {


    const breakpoints = useWidth();
    
    const { isCreatingUser, created, user_password, createUser } = useUsers();
    const { isLoading, getRoles, rolesList, pagination, retrieved, getAllRoles } = useRolesManagement();
    const { getProjectList, projects, loadingProjects } = useProjects();
    const { isFetchingBusinessNames, business_names, getBusinessListNames } = useBusiness();

    const menuPortalTargetRef = useRef(null);

    const [email, setEmail] = useState("");
    const [role_id, setRoleId] = useState("");
    const [role, setRole] = useState("");
    const [project_id, setProjectId] = useState("");
    const [open, setOpen] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [businessId, setBusinessId] = useState("");
    const [selectedBusiness, setSelectedBusiness] = useState("");

    useEffect(() => {
        getAllRoles();
        getBusinessListNames();
    }, []);

    useEffect(() => {
        if(businessId){
            getProjectList(businessId);
        }
    }, [businessId]);

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(true);
        setEmptyEmail(false);
    };

    const handleSubmit = (e) => {

        let payload = {
            email: email,
            role: role_id,
            project_id: project_id
        }

        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        const checkValidEmail = regex.test(email);

        if (email == "" || email == " " || email == "  ") {
            setEmptyEmail(true);

        } else if (!checkValidEmail && email !== "") {
            setValidEmail(false);

        } else {
            setEmptyEmail(false);
            setValidEmail(true);
            createUser(businessId, payload);
        }
    }

    const handleChangeRole = (selectedOption) => {
        // setRole(event.target.value);
        setRole(selectedOption);
        setRoleId(selectedOption?.value);
    }

    const handleChangeProject = (event) => {
        setProjectId(event.target.value);
    };

    const handleChangeBusiness = (selectedOption) => {
        setBusinessId(selectedOption?.value);
        setSelectedBusiness(selectedOption);
    };

    const handleCloseUserPassword = () => {
        setOpen(false);
    };

    const handleCloseAllDialogs = () => {
        handleCloseAddDialog();
        handleCloseUserPassword();
        window.location.reload();
    };

    useEffect(() => {
        if (created) {
            setOpen(true);
        }
    }, [created]);

    const renderRoleDependentFields = () => {
        if (
            [
                "ProjectManager",
                "ProjectQALead",
                "ProjectAdministrator",
                "ProjectOfficer",
                "ProjectSupport",
                "ProjectBillingSpecialist",
                "ProjectAuditor",
            ].includes(role?.label)
        ) {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 550, fontSize: 12 }}>Select Project :</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={project_id}
                                onChange={handleChangeProject}
                            >
                                {projects?.length === 0 && (
                                    <MenuItem value={0} disabled>
                                        No available projects
                                    </MenuItem>
                                )}
                                {projects?.map((data, index) => (
                                    <MenuItem key={data?.id} value={data?.id} sx={{fontSize: 12}}>
                                        {data?.application_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {!project_id && (
                        <Typography sx={{ color: "red", marginLeft: 2, fontSize: 12 }}>
                            Select a project to enable the "Add" button.
                        </Typography>
                    )}
                </>
            );
        }
        return null;
    };

    return (
        <>
            <Dialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                maxWidth={"sm"}
                fullWidth={"sm"}
                PaperProps={{
                    style: {
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        borderRadius: 5,
                        margin: "auto",
                    },
                }}
            >
                <DialogTitle>Add User</DialogTitle>

                <Divider />

                <DialogContent
                    sx={{
                        padding: breakpoints === "xs" || breakpoints === "sm" ? 3 : 6,
                        paddingTop: 2,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}> Email :</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                placeholder="Email"
                                value={email}
                                sx={{fontSize: 12}}
                                onChange={handleChangeEmail}
                                helperText={
                                    <p style={{ fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px" }}>
                                        {emptyEmail ? "Email is required, please input email" : !validEmail ? "Email is invalid, please input valid email" : ""}
                                    </p>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Select Enterprise :</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 3 }}>
                            {isFetchingBusinessNames ? (
                                <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                            ) : (
                                <ReactSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedBusiness}
                                    onChange={handleChangeBusiness}
                                    options={business_names.map(business => ({ value: business.id, label: business.business_name }))}
                                    isSearchable
                                    isClearable
                                    menuPortalTarget={menuPortalTargetRef.current}
                                    menuPosition="fixed"
                                    placeholder={
                                        <Typography sx={{ color: "#000", fontSize: 12 }}>
                                            Select Enterprise
                                        </Typography>
                                    }
                                    styles={{
                                        menuPortal: provided => ({
                                            ...provided,
                                            zIndex: 9999
                                        }),
                                        menu: provided => ({
                                            ...provided,
                                            zIndex: 9999,
                                            fontSize: 12
                                        }),
                                        control: base => ({
                                            ...base,
                                            height: 56,
                                            backgroundColor: 'transparent',
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Select Role :</Typography>
                        </Grid>
                        <Grid item xs={12} ref={menuPortalTargetRef}>
                            <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={role}
                                onChange={handleChangeRole}
                                options={rolesList.map(role => ({ value: role.id, label: role.role }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Role
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                        </Grid>

                        {renderRoleDependentFields()}
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{ padding: breakpoints === 'xs' || breakpoints === 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}
                >
                    <Button sx={{ color: "black", textTransform: "none" }} onClick={handleCloseAddDialog}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={
                            !["ProjectManager", "ProjectQALead", "ProjectAdministrator", "ProjectOfficer", "ProjectSupport", "ProjectBillingSpecialist", "ProjectAuditor"].includes(role) ||
                            !project_id
                        }
                        sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                        startIcon={
                            isCreatingUser && (
                                <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                            )
                        }
                    >
                        {isCreatingUser ? "Adding" : "Add"}
                    </Button>
                </DialogActions>
            </Dialog>

            {open && 
                <UserPasswordDialog 
                    password={user_password} 
                    openDialog={open} 
                    handleClose={handleCloseAllDialogs} 
                    setOpen={setOpen} 
                />
            } 
        </>
    );
};

export default CreateUserDialog;
